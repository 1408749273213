.HomeMainLanding{
    background: url(../img/home_bg.png) no-repeat;  
    height: 100vh;
    background-size: cover;
    .welcome{
        font-size: 30px;
        margin-bottom: 0;
    }
    h1{
        font-size: 50px;
        color: white;
    }
    .sce-rgt-2{
        padding-left: 175px;
    }
    .t-copyright{
        position: absolute;
        bottom: 0;
        left: 45%;

    }
    
    
}