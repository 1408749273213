@import './variables.scss';

.card-org-main{
    background: $cardBlue;
    text-align: center;
    position: relative;
    padding-top: 85px;
    margin-top: 100px;
    padding-bottom: 5px;
    border-radius: 10px;
    cursor: pointer;
    .org-img-nav{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background-color: $white;
        padding: 0px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -65px;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        img{
            width: 100%;
        }
    }
   
    .status{
        span{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 10px;
            }
        }
    }
    .subscription-plan{
        margin-top: 30px;
        p{
            font-weight: $semi-bold;
        }
    }
}
span{
    &.category{
        color: $yellow;
        font-size: 13px;
        margin-bottom: 20px;
        display: block;
    }
}
.phone, .location{
    margin-bottom: 10px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    img{
        margin-right: 10px;
    }
    &.min-h-65{
        min-height: 65px;
    }
}