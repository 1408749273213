@import './variables.scss';

.header-main {
    border-bottom: $border solid 1px;
    padding: 15px;
    width:calc(100% - 275px);
    z-index: 11;
    @media (max-width:950px) {
        width: 100%;
     }
    background-color: $primary;
    .profile-menu {
        .dropdown-toggle {
            padding: 0 !important;
            text-decoration: none;

            &:focus {
                box-shadow: none;
            }

            &:after {
                display: none;
            }

            .profile-dropdown {
                display: flex;
                align-items: center;
                color: $text-primary;

                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                }

                span {
                    color: $text-seconday;
                }

                div {
                    padding: 0 10px;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url('../img/ArrowDownYellow.svg') no-repeat center $secondary;
                    width: 35px;
                    height: 35px;
                    margin-left: 5px;
                    border-radius: 5px;
                }
            }
        }

        &.show {
            .profile-dropdown {
                &:after {
                    transform: rotate(180deg)
                }
            }
        }

        .dropdown-menu {
            border: 0;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
            padding-bottom: 15px;
            margin-top: 10px;
            left: auto;
            right: 0;
            background-color: $secondary;

            .dropdown-item {
                color: $text-primary;
                padding-top: 10px;
                padding-bottom: 10px;

                &:focus,
                &:hover,
                &:active {
                    background-color: $primary;

                }

                svg {
                    margin-right: 12px;
                }
            }

        }
    }
    .bm-burger-button{
        margin-left: 0;
    }
    .header-user-image {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}