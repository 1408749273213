@import './variables.scss';

body{
    background: $primary;
}
.profileCreation{    
    .col-left {
        background: rgb(129, 42, 158);
        background: linear-gradient(0deg, rgba(129, 42, 158, 1) 0%, rgba(5, 38, 90, 1) 76%);
        height:100% !important;
        padding: 30px;
        .prof-details{
           width: 100%; 
           .fileupload-sec{
            height: 80px;
            width: 100% !important;
            }
        }
        }        
    .profile-inn-cont{
        padding:50px 150px;
        @media (max-width:1024px) {
            padding:50px 50px;
        }
    }
    .btn-Done, .btn-cancel{
        margin-left: 0 !important;
    }
    .prof-image {
        width: 130px;
        height:130px;
        border-radius: 50%;
    }
    .avatar-image {
        width: 130px;
        height:130px;
        border-radius: 50%;
        cursor: pointer;
    }
    .react-datepicker__year-dropdown{
        // background-color: rgb(184, 55, 55);
        color: black;
    }
    .col-left-h-100vh{
        height: 100vh !important;
    }
}

