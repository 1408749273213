@import "../css/variables.scss";

.form-label{
    margin-bottom: 6px;
    color: $text-primary;
    &:empty{
        display: none;
    }
    span{
        color: $text-seconday;
    }
}
.form, .cut-select-label{
    margin-bottom: 20px;
}
.bm-menu{
    .form, .cut-select-label{
        margin-bottom: 20px !important;
    } 
}
.form {
    div {
        width: 100%;

        .form-label {
            span {
                &.mandatory {
                    color: $error-red;
                    padding-left: 2px
                }

                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            background-color: $input-Primary;
            border: 1px solid $input-Primary;
            padding: 11px;
            border-radius: 5px;
            color: $inputPH;

            &:focus,
            &:active {
                border: 1px solid #50598C;
                background-color: $input-Primary;
                color: $inputPH;
                box-shadow: none !important;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($white, $alpha: 0.5);
            }

            &::placeholder {
                color: $inputPH;
                opacity: 0.5;
                font-weight: $light;
            }
        }

        .form-text {
            &.error {
                color: $error-red;
                font-size: 13px;
                font-weight: $light;
            }
        }
    }
    .form-check-input[type=checkbox] {
        border-radius: 0.25em;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-color: $tabactive;
    &:checked {
        background-color: $tabactive;
        border-color: $tabactive;
        height: 20px;
        width: 20px;
    }}

    
    .form-check-label {
        color: $text-primary;
        line-height: 27px;
        padding-left: 5px;
    }
}
.btn-eye {
    position: absolute;
    top: 30px;
    right: 0;
    border: none;
    padding: 7px 12px;
    &:hover {
        background: none !important;
    }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $input-Primary;
  -webkit-text-fill-color: $inputPH;
  -webkit-box-shadow: 0 0 0px 1000px $input-Primary inset;
  transition: background-color 5000s ease-in-out 0s;
}

.cut-select-label{
    .filter {
        &__control {
            background-color: $input-Primary;
            border: 0;
            padding: 5px;
            border-radius: 5px;
            box-shadow: none;
            &:hover {
                border-color: $border;
            }
        }
        &__single-value{
            color: $inputPH;
        }
        &__control--is-focused {
            border-color: $border;
        }

        &__indicator-separator {
            display: none;
        }

        &__value-container {
            padding-left: .375rem;
            .filter__input{
                color: $inputPH !important;
            }
        }

        &__menu {
            margin: 1px 0;
            border-radius: 10px;
            padding: 7px 0;
            background-color: #636c9f;
            .filter__menu-list{
                max-height: 190px;
            }
        }
        &__multi-value{
            background: $primary;
            .filter__multi-value__label{
                color: $text-primary;
            }
        }
        &__option {    
            background-color: #636c9f;        
            color: $white;
            word-break: break-word;
            &--is-focused:not(.react-select__option--is-selected) {
                background-color: $cardBlue;
                color: $text-seconday;
            }

            &:hover {
                background: $primary;
            }
        }
        &__placeholder{
            color: $inputPH;
            opacity: 0.5;
            font-weight: $light;
        }
    }
}

.search-main{
    .form{
        margin-bottom: 0;
        .input-group{
            .btn{
                border: 0;
                background: $input-Primary;
                border-left:  #465C80 solid 1px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
.form-sm{
    .form-control{
        padding: 8px !important;
        font-size: 15px;
    }
    .btn{
        padding: 7.5px 12px !important;
    }
    .filter__indicator{
        padding: 3.5px;
    }
    .check-sm{
        form{
        margin-bottom: 15px !important;
    }
    }
    .cut-select-label{
        .filter__control{
            padding: 3px;
            min-height: auto;
        }
    }
}
.page-title, .content-sub-header{
    .cut-select-label{
        margin-bottom: 0;
    }
}
input[type=time] {
    background: url('../img/TimeIcon.svg') no-repeat right 10px top 50%;
    &::-webkit-calendar-picker-indicator{
        opacity: 0;
    }
    }
.error {
    color: $error-red;
    font-size: 13px;
    font-weight: $light;
}   


// Design fix

form {
    div {
        width: 100%;

        .form-label {
            span {
                &.mandatory {
                    color: $error-red;
                    padding-left: 2px
                }

                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            background-color: $input-Primary;
            border: 1px solid $input-Primary;
            padding: 11px;
            border-radius: 5px;
            color: $inputPH;

            &:focus,
            &:active {
                border: 1px solid #50598C;
                background-color: $input-Primary;
                color: $inputPH;
                box-shadow: none !important;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($white, $alpha: 0.5);
            }

            &::placeholder {
                color: $inputPH;
                opacity: 0.5;
                font-weight: $light;
            }
        }

        .form-text {
            &.error {
                color: $error-red;
                font-size: 13px;
                font-weight: $light;
            }
        }
    }
    .form-check-input[type=checkbox] {
        border-radius: 0.25em;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-color: $tabactive;
    &:checked {
        background-color: $tabactive;
        border-color: $tabactive;
        height: 20px;
        width: 20px;
    }}

    
    .form-check-label {
        color: $text-primary;
        line-height: 27px;
        padding-left: 5px;
    }
}
.drop-w-160{
    .filter__menu{
        min-width: 160px;
    }
}