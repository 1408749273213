@import './variables.scss';


.main-menu{
        border: 0 !important;
        width: 275px !important;
        padding: 0;
        height: 100vh;
        position: fixed !important;
        &.ps-broken{
            left: -275px !important;
        }
        &.ps-toggled{
            left: 0 !important;
        }
    .ps-sidebar-container{
        background: $menu-Blue;
        a{
            color: $text-primary;
            padding-left: 15px;
            padding-right: 15px;
            background: rgb(41,41,80);
            background: linear-gradient(90deg, rgba(41,41,80,1) 0%, rgba(41,41,80,1) 100%);
            span{
                margin-right: 5px;
            }
            &:hover, &.ps-active{
                background: rgb(138,5,176);
                background: linear-gradient(90deg, rgba(138,5,176,0.28) 0%, rgba(11,102,155,0.28) 100%);
            }
        }
        .logo-container{
            text-align: center;
            padding: 30px;
            a{
                &:hover{
                    background: transparent;
                }   
            }
        }
    }
}

.main-container{
        padding:0 0 0 275px !important;
        @media (max-width:950px) {
           padding-left: 0 !important;
        }
        .sb-button{
            width: 45px;
            height: 45px;
            background: $secondary;
            border: 0;
            border-radius: 5px;
            position: fixed;
            z-index: 111;
            margin-top: 15px;
            margin-left: 15px;
            @media (min-width:950px) {
                display: none;
             }
        }
        .content-area-main{
            margin-top: 75px;
            padding: 30px 15px;
            min-height: calc(100vh - 128px);
        }
}