@import './variables.scss';

.cover-photo {
    width: 450px;
    height: 275px;
    background-color: $subheadblue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.assign-users {
    .bm-burger-button {
        margin-left: 0;
        height: 46px;
    }
}
.assign-users-comp {
    max-width: 200px;
    margin-right: 15px;
    .bm-burger-button {
        margin-left: 0;
        height: 39px; 
    }
}
.resource-button {
    .bm-burger-button {
        height: 75px;
        margin-left: 0;

        .btn-secondary {
            left: 0;
        }
    }
}

.file-listing {
    .attachmentmentcard-main {
        background-color: $accordionSecondary;
        position: relative;

        .attachment-icon {
            background-color: $accordionSecondary !important;
        }

        .attachment-name {
            background-color: transparent !important;
            padding: 10px 45px 10px 10px;

            small {
                color: $text-seconday;
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border: $tabactive solid 2px;
            border-radius: 4px;
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            cursor: pointer;
            margin: auto;
        }

        &.selected {
            &::after {
                background: url('../img/RadioActive.svg');
                border: 0;
            }
        }
    }
}
.filter-main{
    .cover-photo{
        width: 100%;
        min-height: 200px;
        height: auto;
        .dropzone{
            svg{
                max-width: 30px;
            }
        }
    }
}

.fileupload-sec {
    background-color: #682FF6;
    height: 100px;
    margin: 0 auto;
    border-radius: 4px;
    width: 200px;

    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
    }

    span {
        margin-top: 10px;
        color: #fff;
    }
    &.disabled-fileupload{
        background-color: #6c757d;
        label{
            cursor: default;
        }
    }
}