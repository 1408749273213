.slider-wrap {
    padding:50px 100px 0 100px ;
}
.carousel-item{
    img{
        width: 60%;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
.carousel-caption{
    
    left: 0;
    width: 100%;
    position: relative;
    p{
        color: #F2C2FC;
        font-size:small;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;    
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 50%;
    // border-top: 10px solid transparent;
    // border-bottom: 10px solid transparent; 
    opacity: .5;
    transition: opacity .6s ease;
    position: relative;
    bottom: 25px;
}
.carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background: none;
    border: 1px solid #ffffff;
    bottom: 25px !important;
}
.carousel-indicators .active {
     background-color: #F2C2FC !important;
}
.carousel-control-next, .carousel-control-prev {
    right: 0;
    display: none;
}