@import './variables.scss';

.competenciescard-main {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    &:first-child{
        margin-top: 0;
    }
    .row{
        margin: 0;
    background: $to-toBlue;
    h6{
        font-weight: $semi-bold;
        margin-bottom: 0;
    }
    
    .count{
        background-color: $border;
    }
    .competencies{
        padding: 10px 15px;
        p{margin-bottom: 0; font-size: $font-small; color: $text-primary;}
    }
}
}