@import './variables.scss';

.subscription-main {
    background: $subscriptionBlue;
    text-align: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .subscription-price {
        background: #27338F;
        padding: 40px 0;

        h1 {
            color: #FAAF00;
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 0;
        }

        small {
            font-size: 12px;
            color: $white;
        }
    }

    .subscription-details {
        text-align: left;
        padding: 30px 20px;

        ul {
            padding-left: 0;
            margin-top: 20px;

            li {
                list-style: none;
                display: flex;
                margin-bottom: 15px;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .visibility {
        background-color: rgba(0, 0, 0, 0.4);
        padding: 20px 0;
    }

    &:hover {
        -webkit-box-shadow: 0px 0px 10px -5px rgba(138, 161, 255, 1);
        -moz-box-shadow: 0px 0px 10px -5px rgba(138, 161, 255, 1);
        box-shadow: 0px 0px 10px -5px rgba(138, 161, 255, 1);
    }
}

.subscription-details {
    .subscription-main {
        display: flex;
        align-items: center;
        padding: 15px;
        justify-content: space-between;
        .subscription-price-details {
            display: flex;
            align-items: center;
            .subscription-details{
                padding: 15px 20px;
            }
            .subscription-price {
                border-radius: 10px;
                padding: 30px 50px;
            }
        }
        .visibility{
            padding: 20px;
            border-radius: 10px;
        }
    }
}
.subscribed-list{
    margin-bottom: 16px;
    span{
        background-color: $green;
        padding: 3px 10px;
        color: $white;
        border-radius: 15px;
        font-size: 12px;
    }
}
.nonsubscriber-list{
    span{
        background-color: $error-red;
        padding: 3px 10px;
        color: $white;
        border-radius: 15px;
        font-size: 12px;
    }
}

