@import './variables.scss';

.studentcard-main {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    padding: 15px;
    background-color: $accordionSecondary;
    border: $accordionSecondary solid 1px;
    cursor: pointer;
    &.selected{
        border-color: $green;
    }
    .student-img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;
        img{
            width: 100%;
        }
    }
    &:first-child{
        margin-top: 0;
    }
    .row{
        margin: 0;
    .student-name{
        p{margin-bottom: 0; font-size: 15px; color: $text-primary; text-align: left;}
        small{
            color: $text-seconday;
            display: block;
            margin-top: 2px;
        }
    }
    .approved, .pending{
        svg{
            margin-right: 5px;
        }
    }
}
}
