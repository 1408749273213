@import './variables.scss';

.addMotiv-res {
    .fileupload-sec {
        background-color: #682FF6;
        height: 100px;
        margin: 0 auto;
        border-radius: 4px;
        width: 200px;
    
        .inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
        }

        span {
            margin-top: 10px;
            color: #fff;
        }
    }
}


.btn-Done,
.btn-cancel {
    padding: 11px 70px !important;
    margin-left: 15px !important;

    @media (max-width:1024px) {
        width: 100%;
        margin-left: 0px;
        display: block !important;
        padding: 11px 57px !important;
    }

    &:hover {
        color: $text-primary;
        background-color: $btn-primary;
    }

}

.addOrg-textarea {
    margin-bottom: 20px;
    position: relative !important;
}

.textArea {
    margin-bottom: 20px;
    position: relative !important;
}

.txt-count {
    position: absolute;
    top: 0;
    right: 15px;
}
.txtMessage-count {
    position: absolute;
    top: -24px;
    right: 1px;
}

.content-sub-org {
    padding: 50px 80px;
    .fileupload-sec{
        width: 200px;
        .dropzone{
            height: 100px;
            padding-top: 23px;
        }
    }

    @media (max-width:1024px) {
        padding: 50px 15px !important;
    }

    .row {
        padding-bottom: 10px;



        .org-bt-btn {
            display: flex;
            justify-content: end;

            @media (max-width:768px) {
                display: block !important;
            }

            .btn-Done,
            .btn-cancel {
                padding: 11px 70px !important;
                margin-left: 15px !important;

                @media (max-width:768px) {
                    width: 100%;
                    margin-left: 0px;
                    display: block !important;
                }
            }
        }

    }
}

.org-img {
    max-width: 130px;
}

.org-details {
    h5 {
        font-weight: $regular;
        margin-bottom: 0px;
    }

    span.category {
        margin-bottom: 15px;
    }

    .phone,
    .location {
        display: block;
        margin-bottom: 5px;
    }
}

.label-with-content {
    label {
        font-size: 13px;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 0;
        color: $text-primary;
    }
}

.overview-main {
    h6 {
        margin-bottom: 10px;
    }

    .content-area-padding {
        p {
            font-size: $font-small;
        }
    }

    .card-progress-main {
        padding: 0;

        .card-progress {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .overview_goals_survay {
        text-transform: uppercase;
        font-size: 12px;

        span {
            display: block;
            font-size: 18px;
            font-weight: $bold;
            color: $text-primary;
        }
    }

    .overview_task_event {

        padding: 0;

        p {
            margin-bottom: 6px;
        }

        span {
            font-size: 13px;
            font-weight: $bold;
            color: $text-primary;
            float: right;
            padding-left: 5px;
        }
    }

    .overview_completion {
        .percentage {
            font-size: 18px;
            font-weight: $bold;
            color: $text-primary;
            padding-left: 0;
        }
    }

    .students-goals {
        color: $text-primary;
        font-size: $font-small;

        span {
            font-size: 18px;
            font-weight: $bold;
            color: $text-primary;
        }
    }

    .todo-main {
        padding: 0 7px;

        .col {
            padding-left: 4px;
            padding-right: 4px;

            .todo-status {
                background: $to-toBlue;
                text-align: center;
                font-size: 20px;
                border-radius: 5px;
                color: $text-primary;
                font-weight: $semi-bold;
                overflow: hidden;
                padding-top: 10px;

                span {
                    padding: 10px;
                    display: block;
                    text-transform: uppercase;
                    font-size: 9px;
                    color: $text-primary;
                    font-weight: $semi-bold;
                    margin-top: 10px;
                }

                &.completed {
                    span {
                        background-color: rgba(52, 199, 89, 0.6);
                    }
                }

                &.inprogress {
                    span {
                        background-color: rgba(25, 162, 132, 0.6);
                    }
                }

                &.notstarted {
                    span {
                        background-color: rgba(255, 68, 68, 0.6);
                    }
                }

                &.overdue {
                    span {
                        background-color: rgba(250, 175, 0, 0.6);
                    }
                }
            }
        }
    }
}

.productivity-main {
    h6 {
        padding-bottom: 8px;
    }

    .productive-time {
        h5 {
            margin-bottom: 5px;
            color: $text-seconday;
        }

        p {
            font-size: 12px;
            color: $text-primary;
            margin-bottom: 0;
        }

        .col {
            padding: 0 5px;

            &:first-child {
                padding-left: 15px;
            }

            &:last-child {
                padding-right: 15px;
            }
        }
    }
}

.row_padding {
    padding: 0 7px;
    .overview-main,
    .productivity-main,
    .competencies-main {
        background-color: $secondary;
        margin: 0 7px 25px;
        padding: 0;
        border-radius: 10px;

        .content-sub {
            margin-bottom: 0;
            background-color: transparent;
        }
    }
}

.hand-icon {
    cursor: pointer;
}

.studentscategoryclass {
    padding: 20px;
    border: $border solid 1px;
    border-radius: 10px;

    .btn-container {
        .btn-dark {
            color: #FAAF00;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            min-width: auto !important;

            svg {
                max-width: 15px;
                margin-right: 5px;

                path {
                    fill: #FAAF00;
                }
            }
        }
    }
}