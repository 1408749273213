@import './variables.scss';

.small-text{
    font-size: 13px;
}
.add-input{
    margin-right: 10px !important;
    border-radius: 5px !important;
    background-color: $input-Primary;
    border: 1px solid $input-Primary;
    padding: 11px;
    color: #CCD3FA;
    &:focus,
    &:active {
        border: 1px solid #50598C;
        background-color: $input-Primary;
        color: $inputPH;
        box-shadow: none !important;
    }
    &::placeholder {
        color: $inputPH;
        opacity: 0.5;
        font-weight: $light;
    }
}
.edit-btn{
    padding: 8px 11px 9px 13px !important;
    &.btn-sm{
        padding: 0 !important;
    }
}
.Plus-btn{
    border-radius: 5px !important;
    padding: 10px 13px;
    background-color: $input-Primary;
    border-color: $input-Primary;
    color:$yellow;
}
.Add-btn{
    padding: 10px 25px;
    float: right;
    &:hover{
        color:$yellow !important;
    }
    img{
        padding-right:10px ;
      
    }
} 
.txt-red
{color:$error-red !important;}
.content-sub-org {
    @media (max-width:768px) { 
        padding: 20px !important;
    }
}
.orgAddType-page-title{
    @media (max-width:768px) { 
        h2{
            font-size: 22px !important;
        }
    }
}
.add-Org-type {
    .add-Org-type-L {
        padding: 0;
        background-color: none;
        border-radius: 10px;
        border: 1px solid $border;
        height: 100%;
        h6{
            color: $yellow;
        }
        form, .cut-select-label {
            margin-bottom: 0px;
        }
        .Hd-tit-type {
            padding: 15px !important;
            background: #2B304F !important;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom: 1px solid $border;
            h4{
                margin-bottom: 0;
            }
        }
        .add-Org-cnt-wr{
            padding: 25px;
        }
    }
    .org-cat{
            border-radius: 10px;
            border: 1px solid $border;
        .org-cat-col{            
            padding: 25px;
            h6{
                color: $yellow;
            }
        }
    }
    .org-term{
        border: 1px solid $border;
        .class-l{
            div{
                padding-left: 5px !important;
            }
            padding-left: 0 !important;
        }
    }
    .btn-btm{
        @media (max-width:768px) {
            button{
                width: 100%;
            }
         }
        
    }
}