@import './variables.scss';

.card-progress-main {
    width: 100%;
    //cursor: pointer;
    padding: 0 10px;
    .card-progress {
        background-color: rgba(0,0,0,0.28);
        padding: 20px 40px 30px 40px;
        border-radius: 10px;
        border: $darkBlue solid 1px;

        .perfomance-perc {
            font-size: 28px;
            color: $white;
            font-weight: $semi-bold;
        }

        .progress {
            height: 6px;
            background-color: $btn-primary;

            .progress-bar {
                background: $green;
            }
        } 
    }
    label {
        text-transform: uppercase;
        font-size: 13px;
        padding: 3px 5px;
        text-align: center;
        display: block;
    }
    &:hover{
        .card-progress{
            border: #7EEA1E solid 1px;
        }
    }
}
.progress {
    height: 6px;
    background-color: $btn-primary;
    min-width: 80px;

    .progress-bar {
        background: $green;
    }
}