@import './variables.scss';
body {
    font-family: "Inter", sans-serif !important;   
}
.mng-org-per{
    .org-name-sec{
        .org-name-b{
            background-color: #2B3157;
            border-radius: 5px;
            padding: 15px;
            display: flex;
            .usr-name{
                span{
                    color: #9CAFCE;
                }
            }
            .org-name-icn{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: $secondary;
                display: flex;
                justify-content: center;
                img{
                    width: 20px;
                }
            }
            .check-sm{
                position: absolute;
                right: 15px;
                top: 26px;
            }
        }
    }

}