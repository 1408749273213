.btn-resolved {
    background-color: #1C7C67;
    min-width: 110px;
}

.btn-resolv {
    min-width: 110px;
}

.mb-f {
    font-size: 12px !important;
}

.name-desi-sec {
    img {
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
        background-color: #1C7C67;
        margin-right: 10px;
    }
}

.res-slider {
    background: black;
}

.slide-box {
    cursor: pointer;
}

.resources-wrap {

    .content-sub {
        .cut-select-label {
            margin-bottom: 0;
        }

        // .bm-burger-button {
        //     margin-right: 5px;
        // }

        .form-sm .cut-select-label .filter__control {
            // padding: 6px;
            min-width: 150px;
        }

    }

    .content-sub-thumb {
        .thum-box {
            img {
                width: 100%;
                cursor: pointer;
                max-height: 186px;
                min-height: 186px;
            }

            .slid-name {
                width: 100%;
                background: linear-gradient(90deg, rgb(62 21 73) 0%, rgb(17 38 107 / 94%) 100%);
                padding: 15px 20px 1px;
                p{
                    text-transform: capitalize;
                }
            }
        }
    }

    .slick-prev {
        left: -20;
    }

    .slick-next {
        right: -20;
    }

    .slick-prev:before {
        content: url("../img/icn-arrow-lft.svg");
    }

    .slick-next:before {
        content: url("../img/icn-arrow-rgt.svg");
    }

    .slick-initialized .slick-slide {
        padding: 0 3px;
    }

    .slick-slider {
        width: 95%;
        background: black;
        margin-left: 31px;

        @media (max-width:1024px) {
            margin-left: 20px;
        }

        .slide-box {

            img {
                width: 275px;
                height: 198px;
            }

            .slid-name {
                width: 100%;
                background: rgb(2,50,78);
                background: linear-gradient(267deg, rgb(2, 50, 78) 0%, rgb(65 7 82) 76%);    
                padding: 15px 20px 1px;
            }
        }

    }

    .select-res-rep {
        margin-bottom: 0;
    }

    .res-addMot-btn {
        float: right;
    }

    .view-all-btn {
        color: #33E3E9;
    }

}
.res-att{
    .att-img-vid{
        max-height: 325px !important;
    }
}
//*--------Report COntent Issue  -------*///
.accordion-body {
    border-top: #313D67 solid 1px !important;
}

.p-gray {
    color: #9CAFCE;
}

.rep-contIssue-wr {
    .acco-body {
        border-top: 1px solid red;
    }

    .content-sub-header {
        .nav-drop {
            position: absolute;
            right: 65px;
            z-index: 9999;
        }
    }

    .r-rep-contIssue {
        margin-bottom: 15px;

        .res-det-img {
            position: relative;
            background-color: #2B3157;
            img {
                border-radius: 10px !important;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .img-overlay {
                background: rgb(0, 0, 0);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.756827731092437) 100%);
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 20px;
                border-radius: 10px;
                color: white;

                .card-title {
                    margin-bottom: 10px;
                }

                .created-pro-img {
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50% !important;
                        background-color: #1C7C67;
                        margin-right: 10px;
                    }

                }

                .creater-name-sec {
                    h6 {
                        margin-bottom: 2px !important;
                    }
                }
            }

        }

        .r-sec-rgt {
            .label-with-content {
                // border-top: #313D67 solid 1px !important;
                padding-top: 10px;
                margin-top: 20px;

                label {
                    color: #9CAFCE;
                }
            }

            h5 {
                padding-bottom: 15px !important;
            }
        }
    }

    .Rep-IssueIcn {
        padding-right: 10px !important;
    }

}

//*--------Report COntent Issue  -------*///

.videoList {
    img {
        width: 100%;
    }
}

.addMotiv-res {
    .sel-fileType {
        padding: 0 300px;

        @media (max-width:1024px) {
            padding: 0;
        }
    }

    .sec-addThunmb {
        background: #2D3257;
        max-width: 400px;
        margin-top: 50px;
        border-radius: 10px;
        height: 300px;

    }

    .att-list-b {
        background: #2B3157;
        padding: 20px;
        border-radius: 10px;

        .text-ar {
            padding-left: 15px;

            p {
                margin-bottom: 0;
            }
        }

    }
}