@import './variables.scss';


.perfomance-main{
    padding:25px 60px;
    .slick-next{
        background: url('../img/Icn_Next.svg') no-repeat;
        background-size: cover !important;
        right: -42px;
        &:hover, &:focus{
            background: url('../img/Icn_Next.svg') no-repeat; 
            opacity: 1;
        }
        &.slick-disabled{
            opacity: 0.3;
            pointer-events: none;
        }
    }
    .slick-prev{
        background: url('../img/Icn_Prev.svg') no-repeat;
        background-size: cover !important;
        left: -42px;
        &:hover, &:focus{
            background: url('../img/Icn_Prev.svg') no-repeat; 
            opacity: 1;
        }
        &.slick-disabled{
            opacity: 0.3;
            pointer-events: none;
        }
    }
    .slick-prev, .slick-next{
        opacity: 0.9;
        width: 40px;
        height: 40px;
        min-width: 40px;
        line-height: 40px;
        font-size: 16px;
        margin-top: -22px;
        &::before{
            display: none;
        }
        &:hover{
            &:enabled{
                opacity: 1; 
            }
        }
        &[disabled]{
            background: rgba(255,255,255,0.1);  
        }
    }
}
.dashboard-tiles-main{
    .dash-tile{
        margin-bottom: 30px;
        background-color: $secondary;
        border-radius: 10px;
        background-repeat: no-repeat;
        &.students{
            background: rgb(254,186,150);
            background: linear-gradient(135deg, rgba(254,186,150,1) 0%, rgba(255,102,102,1) 100%);
        }
        &.accountability{
            background: rgb(137,198,247);
            background: linear-gradient(135deg, rgba(137,198,247,1) 0%, rgba(121,58,230,1) 100%);
        }
        &.teachers{
            background: rgb(128,216,209);
            background: linear-gradient(135deg, rgba(128,216,209,1) 0%, rgba(29,167,209,1) 100%);
        }
        &.managers{
            background: rgb(234,221,130);
            background: linear-gradient(135deg, rgba(234,221,130,1) 0%, rgba(246,132,128,1) 100%);
        }
        &.admins{
            background: rgb(247,137,239);
            background: linear-gradient(135deg, rgba(247,137,239,1) 0%, rgba(161,58,230,1) 100%);
        }
        &.greentile{
            background: rgb(218,248,136);
            background: linear-gradient(135deg, rgba(218,248,136,1) 0%, rgba(61,155,54,1) 100%);
        }
        h2{
            font-size: 40px;
            color: $white;
            font-weight: $semi-bold;
            margin-bottom: 0;
        }
        span{
            color: $white;
            font-weight: $semi-bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 2 auto;
            min-width: 150px;
            width: 150px;
        }
        .dash-tile-bg{
            background: url('../img/TileBG.svg') no-repeat right;
            padding: 40px 20px;
            background-size: contain;
        }
    }
}