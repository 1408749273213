@import './variables.scss';

.orglist-main {
    background: $to-toBlue;
    padding: 15px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: $tabactive solid 2px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        cursor: pointer;
        margin: auto;
    }
    &.selected{
        &::after {
            background: url('../img/RadioActive.svg');
            border: 0;
        }
    }
    .org-list {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding-right: 55px;
        margin-right: 0px;

        img {
            max-width: 45px;
            border-radius: 50%;
            margin-right: 10px;
            min-height: 45px;
        }

        .org-details {
            h6 {
                margin-bottom: 3px;
            }

            small {
                color: $text-seconday;
            }
        }
    }
}