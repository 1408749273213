@import './variables.scss';

.myprof-cont {
    .custom-berger-menu-chose-avat {
        background: #682FF6;
        // width: 100px;
        border-radius: 5px;
        // width: max-content;
        display: block;
        padding: 20px 15px;
        text-align: center;

        @media (max-width:1024px) {
            padding: 20px 9px !important;
        }

        .avt-selected {
            display: block !important;
        }

        .bm-burger-button {
            margin-left: 0 !important;
        }

        .bm-menu-wrap {
            text-align: center;
        }
    }

    .avat-wrp {
        display: table-caption;

        img {
            padding-right: 5px;
            margin-bottom: 5px;
        }

        span {
            display: block;

            img {
                padding-top: 5px;
            }
        }
    }

    .btn-chose-avatar {
        background: url(../img/icn-avatar.svg) no-repeat;
        border-radius: 0 !important;
        padding-bottom: 7px;
    }

    .mypro-sec-L {
        background-color: $secondary;
        height: auto;
        border-radius: 10px;
        position: relative;
        justify-content: center;
        margin: 0 15px;
        padding: 0 0 15px;
        @media (max-width:989.9px) { 
            margin: 0 0 30px 0 !important;
            padding: 0 15px;
        } 

        .mng-pro-det {
            display: block;
            text-align: center;
            padding-top: 88px;
        }

        .pro-bg {
            background: url(../img/pro-img-bg.png) no-repeat;
            height: 120px;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            background-size: cover;
        }

        .prof-wrp {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            background-color: white;
            position: absolute;
            top: 36px;
            left: 0;
            right: 0;
            margin: auto;
        }

        p {
            margin-top: 90px;
        }

        .fileupload-sec {
            background-color: #682FF6;
            padding: 15px 20px;
            border-radius: 4px;
            margin-left: 10px;
            margin-right: 10px;
            width: 137px;
            height: 80px;

            @media (max-width:1024px) {
                // width: 100%;
                margin-left: 5px;
                margin-right: 5px;
                min-width: 106px !important;
                padding: 15px 9px;

            }

            .inputfile {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }

            span {
                margin-top: 10px;
                color: #fff;
            }
        }

        .fileupload-sec label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .zip-col {
            margin-left: 5;
        }
    }
    .content-sub{
        margin-right: 15px;
    }
}

.content-prof-user {
    h6 {
        color: #AABBD8;
    }
}

.user-wrap {
    background-color: #2B3157;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    .usr-image img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .usr-name {
        margin-left: 10px;
        padding-top: 3px;

        p {
            margin-bottom: 0 !important;
            color: white;
        }
    }

    .dot-drop {
        position: absolute;
        right: 20px;
        top: 25px;
    }
}

.add-usr-btn {
    width: 39px;
    height: 39px;
    background: #3B426C;
    border-radius: 5px;
    margin-left: 15px;
    border: none;
    padding: 0 !important;
}
.avatar-image {
    width: 130px;
    height:130px;
    border-radius: 50%;
    cursor: pointer;
}

.hand-icon {
    cursor: pointer;
}

.radioUncheck {
    display: block;
    width: 20px;
    height: 20px;
    border: #1C7C67 solid 1px;
    border-radius: 50%;
    position: absolute;
    left: 10px;
}