@import './variables.scss';

.teacherplanlist-main {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.4);
    padding: 50px 20px 30px;
    position: relative;

    h4 {
        font-weight: bold;
    }

    h1 {
        font-size: 45px;
        font-weight: bold;
        color: $yellow;
        margin-bottom: 0;
        line-height: 38px;
    }

    .discount-label {
        background: url(../img/DiscountBG.svg) no-repeat left;
        position: absolute;
        right: 0;
        top: 0;
        padding: 7px 25px 7px 30px;
        font-weight: bold;
        font-size: 15px;
        color: $white;
    }
}

.plantotalamount {
    border-top: $violet dashed 2px;
    padding: 20px 0 10px;
    .total-amount {
        color: #33E3E9;
        font-weight: bold;
        font-size: 15px;

        span {
            font-size: 20px;
        }
    }
}
.planlist-myorganization{
    .teacherplanlist-main{
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding-top: 30px;
        .d-grid{
            display: block;
        }
        h6{
            margin-bottom: 0;
        }
        .mb-4{
            margin-bottom: 1rem !important;
        }
    }
}
.payment-method-main{
    min-height: 307px;
.payment-methods{
    border: $border solid 1px;
    border-radius: 5px;
    color: $text-primary;
    img{
        margin-right: 10px;
    }
}
}