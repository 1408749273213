@import './variables.scss';

.attachmentmentcard-main {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    &:first-child{
        margin-top: 0;
    }
    .row{
        margin: 0;
    background: $to-toBlue;
    h6{
        font-weight: $semi-bold;
        margin-bottom: 0;
    }
    .attachment-name{
        padding: 10px 15px;
        background-color: #272C4E;
        p{margin-bottom: 0; font-size: $font-small; color: $text-primary; text-align: left;}
    }
    .attachment-icon{
        background-color: #303763;
        img{
            max-width: 40px;
        }
    }
}
}
.attachment-list {
    .attachmentmentcard-main {
        background-color: $accordionSecondary;
        cursor: pointer;
        position: relative;
        .attachment-icon {
            background-color: $accordionSecondary !important;
        }
         .attachment-name{
            background-color: $accordionSecondary !important;
            small{
                color: $text-seconday;
                display: block;
            }
        }
        .attachment-delete{
            background-color: $accordionSecondary !important;
            display: flex;
            align-items: center;
        }
    }
}
.addmessage-main{
    .attachmentmentcard-main{
        .attachment-name{
            background-color: transparent;
            small{
                display: block;
                color: $text-seconday;
            }
        }
        .attachment-delete{
            .icon-btn{
                min-width: auto;
            }
        }
    }
}