@import './variables.scss';

.org-selected {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-right: 55px;
    margin-right: 0px;
    .goal-task-img{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: $input-Primary;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 10px;
        img{
            border-radius: 0;
        }
    }

    img {
        max-width: 45px;
        border-radius: 50%;
        margin-right: 10px;
        min-height: 45px;
    }
    .org-details {
        h6 {
            margin-bottom: 3px;
        }
        &::after{
            content: '';
            display: block;
            width: 37px;
            height: 37px;
            background: url('../img/ArrowDownYellow.svg') no-repeat center $input-Primary;
            border-radius: 5px;
            position: absolute;
            right: 5px;
            top: 0px;
        }
    }
    .org-img-container{
        width: 45px;
        height: 45px;
        overflow: hidden;
        margin-right: 10px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        img{
            width: 100%;
        }
    }
}
.competencies-tiles-main{
    margin-bottom: 15px;
    .row{
        margin: 0;
        > div{
            padding-left: 3px;
            padding-right: 3px;
            // &:first-child{
            //     padding-left: 15px;
            // }
                // &:last-child{
                //     padding-right: 6px;
                // }
            .dash-tile-bg{
                padding-left: 17px;
                padding-right: 17px;
                padding-top: 28px;
                padding-bottom: 28px;
            }
        }
        .dash-tile{
            margin-bottom: 6px;
        }
    }
}
