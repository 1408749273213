@import './variables.scss';

body{
    background: $primary;
}
.log-lft-bg {
    background: url('../img/log-lft-bg.png') no-repeat;
    background-size: cover;
    height: 100vh;
    @media (max-width:768px) {
        height: auto;
        padding: 50px 0;
    }
    .log-vector-lt {
        padding-top: 70px;
        @media (max-width:768px) {
            display: none;
        }
    }
}

.lg-rgt-b {
    padding: 0 !important;

    .lg-rgt {
        background: $primary;
        height: 100vh;
        @media (max-width:768px) {
            height: auto;
            padding: 50px 0;
        }
        h2 {
            margin: 35px 0;
        }
    }
}