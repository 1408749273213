@import './variables.scss';

.form-check-input[type=radio] {
    border-radius: 50%;
    border:1px solid #1C7C67;
    height: 20px;
    width: 20px;
    background: transparent;
}
.form-check-input:checked[type=radio] {
    background-image: url(../img/check-green.svg);
    
}
.permision-from-check{
    .form-check{
        width:auto;
        padding-left: 50px;
    }
    h6{
        padding-top: 5px;
    }
}