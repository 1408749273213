.kud-pnt-bg-bl {
    background: rgb(31, 14, 79);
    background: linear-gradient(0deg, rgba(31, 14, 79, 1) 3%, rgba(40, 23, 88, 1) 10%, rgba(80, 46, 175, 1) 76%);
    height: 92px;
    border-radius: 6px;

    .sec-left-lev {
        padding: 15px;
        background: rgb(35, 21, 74);
        background: linear-gradient(0deg, rgba(35, 21, 74, 1) 3%, rgba(69, 41, 147, 1) 76%);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 50%;
        justify-content: center;

        img {
            max-width: 50px;
            margin-right: 5px;
        }
    }

    .sec-rgt-lev {
        width: 50%;
    }
}

.kud-pnt-bg-bl-g {

    background: rgb(21, 74, 50);
    background: linear-gradient(0deg, rgb(21, 74, 52) 3%, rgb(41, 147, 113) 76%);
    height: 92px;
    border-radius: 6px;

    .sec-left-lev {
        padding: 15px;
        background: rgb(14, 62, 52);
        background: linear-gradient(0deg, rgba(14, 62, 52, 1) 3%, rgba(28, 124, 103, 1) 76%);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 50%;
        justify-content: center;

        img {
            max-width: 50px;
            margin-right: 5px;
        }
    }

    .sec-rgt-lev {
        width: 50%;
    }
}

.kud-pnt-bg-gr {
    background: rgb(14, 62, 52);
    background: linear-gradient(0deg, rgba(14, 62, 52, 1) 3%, rgba(28, 124, 103, 1) 76%);
    height: 92px;
    border-radius: 6px;
    padding: 15px;

    .sec-left-lev {
        padding: 15px;
        background: rgb(35, 21, 74);
        background: linear-gradient(0deg, rgba(35, 21, 74, 1) 3%, rgba(69, 41, 147, 1) 76%);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 50%;
        justify-content: center;

        img {
            max-width: 50px;
            margin-right: 5px;
        }
    }

    img {
        width: 26px;
        height: 24px;
        float: left;
        margin-right: 5px;
    }

    h3 {
        float: left;
    }
}

.kud-pnt-bg-rd {
    background: rgb(78, 26, 92);
    background: linear-gradient(0deg, rgba(78, 26, 92, 1) 3%, rgba(155, 52, 184, 1) 76%);
    height: 92px;
    border-radius: 6px;

    .sec-left-lev {
        padding: 15px;
        background: rgb(66, 21, 79);
        background: linear-gradient(0deg, rgba(66, 21, 79, 1) 3%, rgba(132, 42, 158, 1) 76%);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 50%;
        justify-content: center;

        img {
            max-width: 50px;
            margin-right: 5px;
        }
    }

    h3 {
        float: left;
    }
}

.pts-txt {
    font-size: 18px;
    font-weight: 600;
    color: #FAAF00;
}

.custom-berger-menu-kudos .bm-burger-button {
    display: flex !important;
    justify-content: center;
}