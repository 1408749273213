@import './variables.scss';

.notification-main{
    background: #2B3157;
    border-radius: 5px;
    margin-bottom: 15px;
    .notification-icon{
        padding: 15px 15px 15px 30px;
    }
    .notification-text{
        padding: 20px 15px;
        p{
            margin-bottom: 5px;
            color: $white;
            font-size: 15px;
    font-weight: normal;
        }
    }
}