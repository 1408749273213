@import './variables.scss';

.sec-b{
    border: 1px solid #313D67 !important;
    border-radius: 10px;
}
.txt-white{
    color: white !important;
}
.sec-type-det-L{
    .sec-type-det-L-header{
        padding:15px;
        border-bottom: 1px solid #313D67;
    }  
}