//Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
$primary-font: 'Inter', sans-serif;

//Primary Colors
$primary: #1F223A;
$secondary:#24294A;
$text-primary:#E6E6F6;
$text-seconday: #9CAFCE;
$btn-primary:#2C41EF;
$btn-secondary: #22A4D2;
$input-Primary: #3B426C;
$inputPH: #CCD3FA;
$menu-Blue: #292950;
$white:#fff;
$black: #000;
$error-red: #f00;
$readonly:#9E9E9E;
$border: #313D67;
$darkBlue: #1A1E35;
$cardBlue: #1E2240;
$green: #349F53;
$yellow:#FAAF00;
$subheadblue: #2B304F;
$to-toBlue: #272E59;
$tabactive: #1C7C67;
$iconButton: #3F2593;
$border-b:#313D67;
$violet: #682FF6;
$subscriptionBlue: #15182B;
$accordionSecondary: #2B3157;
$teal: #33E3E9;
//Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold:800;

//Font Size
$font-small:13px;
