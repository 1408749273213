@import "../css/variables.scss";

.form-label{
    margin-bottom: 6px;
    color: $text-primary;
    &:empty{
        display: none;
    }
    span{
        color: $text-seconday;
    }
}
.form, .cut-select-label{
    margin-bottom: 20px;
}
.form {
    div {
        width: 100%;

        .form-label {
            span {
                &.mandatory {
                    color: $error-red;
                    padding-left: 2px
                }

                &.tooltip-wrap {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        .form-control {
            background-color: $input-Primary;
            border: 1px solid $input-Primary;
            padding: 11px;
            border-radius: 5px;
            color: $inputPH;

            &:focus,
            &:active {
                border: 1px solid #50598C;
                background-color: $input-Primary;
                color: $inputPH;
                box-shadow: none !important;
            }

            &:read-only {
                border: 1px solid $readonly;
                color: rgba($white, $alpha: 0.5);
            }

            &::placeholder {
                color: $inputPH;
                opacity: 0.5;
                font-weight: $light;
            }
        }

        .form-text {
            &.error {
                color: $error-red;
                font-size: 13px;
                font-weight: $light;
            }
        }
    }
}
.error {
    color: $error-red;
    font-size: 13px;
    font-weight: $light;
}   


.payment-popup input,
.StripeElement{
    background-color: $input-Primary;
    border: 1px solid $input-Primary;
    padding: 15px;
    border-radius: 5px;
    color: $inputPH;

    &:focus,
    &:active {
        border: 1px solid #50598C;
        background-color: $input-Primary;
        color: $inputPH;
        box-shadow: none !important;
    }

    &:read-only {
        // border: 1px solid $readonly;
        color: rgba($white, $alpha: 0.5);
    }

    &::placeholder {
        color: $inputPH;
        opacity: 0.5;
        font-weight: $light;
    }
}