@import './variables.scss';

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  color: $text-seconday;
}

h1 {
  font-size: 32px;
  font-weight: $semi-bold;
  color: $text-seconday;
}

h2 {
  font-size: 24px;
  font-weight: $regular;
  color: $text-primary;
}

h3 {
  font-size: 22px;
  font-weight: $regular;
  color: $text-primary;
  text-transform: capitalize;
}

h4 {
  font-size: 18px;
  color: $text-primary;
  font-weight: 500;
  text-transform: capitalize;
}

h5 {
  font-size: 16px;
  font-weight: $semi-bold;
  color: $text-primary;
  text-transform: capitalize;
}

h6 {
  font-size: 14px;
  font-weight: $medium;
  color: $text-primary;
}
.mt-6{
  margin-top: 6px;
}
.mt-25{
  margin-top: 25px;
}
.max-w-140 {
  max-width: 140px;
  &.org-details {
    small,
    h6 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.font-xs {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-sm {
  font-size: 13px;
}

small {
  line-height: 17px;
}

.z-index-2 {
  z-index: 2;
}

.text-secondary {
  color: $text-seconday !important;
}

.text-teal {
  color: $teal;
}

p {
  color: $text-seconday;
  word-break: break-word;
}

a {
  color: $text-seconday;
  text-decoration: none;

  &:hover {
    color: $text-primary;
  }
}

a,
button,
path {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.inn-bg {
  background-color: #2b3157;
  border-radius: 5px;
}

.inprogress {
  color: #19a284;
}

.bdr-content-area {
  border: $border solid 1px;
  border-radius: 10px;
}

.btn {
  padding: 11px;

  &.icon-btn {
    width: 46px;
  }

  &.btn-sm {
    padding: 8px 15px;

    &.icon-btn {
      width: 37px;
      height: 37px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.btn-outline-primary {
    border-color: $btn-secondary;
    color: $btn-secondary;

    &:hover,
    &:active {
      color: $white;
      background-color: $btn-secondary;
    }
  }

  &.btn-icon-top {
    text-align: center;
    padding: 12px 11px;

    span {
      display: block;
      margin-bottom: 6px;

      svg {
        margin-right: 0;
      }
    }
  }

  .badge {
    border-radius: 15px;
  }
}

.emial-verify {
  width: auto;
  margin-bottom: 20px;

  Input:first-child {
    margin-left: 0;
  }

  Input:last-child {
    margin-right: 0;
  }

  Input {
    text-align: center;
    width: 4em !important;
    height: 4em;
    border-radius: 5px;
    border: none;
    background-color: #3b426c;
    margin: 10px;
    color: white;
  }
}

.icon-btn-more {
  .dropdown-toggle {
    width: 37px;
    height: 37px;
    text-align: center;
    background: $input-Primary;
    border-radius: 4px;
  }
}

.btn-primary {
  background-color: $btn-primary;
  border: 0;
}

.btn-secondary {
  background-color: $violet;
  border: 0;

  &:hover,
  &:active {
    background-color: $iconButton;
  }

  &.w-100 {
    svg {
      margin-right: 10px;
    }
  }
}

.btn-dark {
  background: rgba(59, 66, 108, 0.5) !important;
  border: 0;

  &:hover {
    background-color: $primary;
  }
}

.border-b {
  border: $border solid 1px !important;
}

.border-top {
  border-top: $border solid 1px !important;
}

.border-bottom {
  border-bottom: $border solid 1px !important;
}

.border-start {
  border-left: $border solid 1px !important;
}

.no-margin {
  > * {
    margin: 0 !important;
  }
}

.log-lft-bg {
  background: url('../img/log-lft-bg.png') no-repeat;
  background-size: cover;
  height: 100vh;
  align-items: center;
  .log-vector-lt {
    padding-top: 70px;
  }
}

.lg-rgt-b {
  padding: 0 !important;

  .lg-rgt {
    background: #1f223a;
    height: 100vh;

    h2 {
      margin: 35px 0;
    }

    .inp-pas {
      position: relative;
    }

    .ForgotP {
      h2 {
        margin-bottom: 10px !important;
      }

      p {
        color: $text-seconday;
        padding-bottom: 30px;
      }
    }
  }
}

.page-title {
  margin-bottom: 20px;
}

.content-sub {
  background: $secondary;
  border-radius: 10px;
  margin-bottom: 25px;

  .content-sub-header {
    padding: 12px 15px;
    border-bottom: $border solid 1px;
    border-radius: 10px 10px 0 0;
    min-height: 62px;

    &.header-light-bg {
      background: $subheadblue;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
      margin-top: 5px;
      font-size: 13px;
    }

    .btn-container {
      .custom-berger-menu {
        .bm-burger-button {
          a {
            &.btn-sm {
              line-height: 24px;
            }
          }
        }
      }
    }

    .userthumnail {
      label {
        font-size: 15px;
        color: $text-primary;

        span {
          color: $text-seconday;
        }
      }
    }
  }

  .content-area-padding {
    padding: 15px;

    .btn-container {
      .btn {
        min-width: 170px;
        @media (max-width: 400px) {
          min-width: auto;
        }
      }

      .bm-burger-button {
        height: auto;
        margin-left: 0;

        a {
          &.btn {
            margin-left: 0;
          }
        }
      }
    }
  }

  &.content-sub-secondary {
    background-color: #282e56;
  }
  &.profileContent {
    margin: 0 15px;
  }
}

.btn-container {
  .btn {
    margin-left: 15px;
  }

  &.btn-min-width {
    .btn {
      min-width: 150px;
    }
  }

  .bm-menu {
    .btn {
      margin-left: 0;
    }
  }
}
.pop-btn-container {
  .btn {
    min-width: 80px;
  }
}
.dropdown {
  .dropdown-toggle {
    text-decoration: none;

    &:focus {
      box-shadow: none;
    }

    &:after {
      display: none;
    }

    .profile-dropdown {
      display: flex;
      align-items: center;
      color: $text-primary;

      p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
      }

      span {
        color: $text-seconday;
      }

      div {
        padding: 0 10px;
      }
    }
  }

  &.show {
    .profile-dropdown {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);
    padding-bottom: 10px;
    left: auto;
    right: 0;
    background-color: $input-Primary;

    .dropdown-item {
      color: $text-primary;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: $light;
      font-size: 14px;

      &:focus,
      &:hover,
      &:active {
        background-color: $primary;
      }

      svg {
        margin-right: 12px;
      }
    }
  }
}

.overlay-dropdown {
  .nav-link {
    padding: 0;
  }

  .dropdown {
    width: 100%;

    .dropdown-menu {
      width: 100%;
    }
  }
}

.breadcrumb {
  margin: 0px;
  font-size: 12px;

  .breadcrumb-item + .breadcrumb-item {
    &:before {
      color: $text-seconday;
      content: var(--bs-breadcrumb-divider, '>');
      float: left;
      padding-right: var(--bs-breadcrumb-item-padding-x);
    }
  }

  .breadcrumb-item {
    &.active {
      color: $text-seconday;
    }
  }
}

// Tab

.nav-tabs {
  border-bottom: 0;

  li {
    .nav-link {
      color: $text-seconday;
      font-weight: $medium;
      font-size: 15px;
      padding: 10px 20px;
      border: 0;

      &:hover {
        border: 0;
        color: $text-primary;
      }

      &.active {
        background: $tabactive;
        color: $white;
        border: 0;
        border-radius: 5px;
      }
    }
  }
}

// Table

.table {
  font-size: $font-small;
  vertical-align: middle;

  &.table-striped {
    tbody {
      tr {
        height: 42px;

        &:nth-of-type(even) > * {
          background-color: $cardBlue;
          color: $text-seconday;
        }

        &:nth-of-type(odd) > * {
          background-color: $secondary;
          color: $text-seconday;
        }

        td {
          border: 0;

          .btn-sm {
            &.icon-btn {
              width: 33px;
              height: 33px;
              padding: 0;
              background: $iconButton;

              &:hover {
                background: $border;
              }
            }
          }

          .td-progressbar {
            font-size: 14px;
            color: $white;

            span {
              &.inprogress {
                color: #19a284;
              }
            }
          }
        }
      }
    }
  }

  thead {
    th {
      background: $cardBlue;
      color: $text-primary;
      padding: 13px 10px;
      border-bottom: $border solid 1px;
      vertical-align: middle;
      font-weight: $semi-bold;
    }
  }

  .no-margin {
    form,
    .form {
      margin: 0 !important;
    }
  }

  &.table-sm {
    th,
    td {
      padding: 8px;
    }

    .btn-sm {
      width: 33px;
      height: 33px;
      padding: 0;
    }
  }
  &.min-w-table {
    min-width: 900px;
  }
}

.td-user-image {
  width: 30px;
  height: 30px;
  background: $secondary;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  display: block !important;
}

.custom-berger-menu-btn {
  background-color: #2c41ef;
  border: 0;
  padding: 7px 30px;
  border-radius: 10px;
  color: white;

  .bm-burger-button {
    margin-left: 0;
  }
}

.multiple-user {
  color: $text-seconday;

  .td-user-image {
    margin-right: 0;
    margin-left: -10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 10px;
    }
  }
}

// Pagination
.pagination {
  margin-bottom: 0;

  .page-item {
    &.active {
      .page-link {
        background-color: $secondary;
        border-color: $border;
        color: $white;
      }
    }

    .page-link {
      color: $input-Primary;
      border-color: $border;
    }
  }

  li {
    box-shadow: none;

    a {
      // font-size: $main-fs;
      // font-weight: $medium;
      color: #528dee !important;
      padding: 8px 15px;
      border: $border solid 1px;
      display: inline-flex;
      background: #252f52;

      &:hover {
        color: $text-primary;
      }
    }

    &.pagination__link--active {
      a {
        color: $white !important;
        background: $btn-primary;
        padding: 9px 15px;
        border: none;
      }
    }
    &:first-child {
      a {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {
      a {
        border-radius: 0 5px 5px 0;
      }
    }
    &.pagination__link--disabled {
      a {
        color: #4f5585 !important;
        border-color: $accordionSecondary !important;
      }
    }
  }
}

.h-100 {
  height: 100%;
}

.h-100px {
  min-height: 100px !important;
}

.w-50px {
  width: 50px;
}
.w-70px {
  width: 70px;
}
.w-75px {
  width: 75px;
}

.w-100px {
  width: 100px;
}
.w-180px {
  width: 180px;
  word-break: break-all;
}
.h-40vh {
  height: 40vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-42vh {
  height: 42vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-53vh {
  height: 53vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-60vh {
  height: 60vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-63vh {
  height: 63vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-80vh {
  height: 78vh !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-800px {
  height: 800px !important;

  > div:first-child {
    padding-right: 10px;
  }
}
.h-250px {
  max-height: 800px !important;
  min-height: 250px;
  height: auto !important;

  > div:first-child {
    padding-right: 10px;
  }
}

#App {
  font-family: sans-serif;
  height: 100vh;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  //margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  width: 39px;
  height: 39px;
  background: url('../img/Filter.svg') no-repeat center $input-Primary;
  border-radius: 5px;
  margin-left: 15px;

  button {
    position: relative !important;
  }

  span {
    display: none;
  }

  &.active {
    background-color: $tabactive;
  }
}

.custom-berger-menu {
  .bm-burger-button {
    width: auto;
    background: none;
    position: relative;
    z-index: 1 !important;

    button {
      position: absolute !important;
    }
  }
  &.header-org {
    @media (max-width: 949.9px) {
      margin-left: 60px !important;
    }
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 15px !important;
  right: 15px !important;
}

/* General sidebar styles */
.bm-menu {
  background: $secondary;
  padding: 50px 1.5em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
  left: 0;
  top: 0;
}

.bm-menu-wrap {
  right: 0;
  top: 0;
}

.tab-secondary {
  .nav-tabs {
    justify-content: center;

    li {
      .nav-link {
        padding-left: 40px;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: $tabactive solid 1px;
          border-radius: 50%;
          position: absolute;
          left: 10px;
        }

        &.active {
          background: transparent;

          &::before {
            background: url('../img/RadioActive.svg');
            border: 0;
          }
        }
      }
    }
  }
}

.dropzone {
  background: $violet;
  padding: 10px;
  border-radius: 5px;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  svg {
    max-width: 34px;

    path {
      fill: $white;
    }
  }

  label {
    font-size: 16px;
  }
}

.download-icn {
  color: #fff;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    margin-right: 5px;
  }
}

.upload-helper-text {
  ul {
    padding-left: 0;
    margin-top: 10px;

    li {
      margin-left: 15px;
    }
  }
}

// Accordion
.accordion {
  .accordion-item {
    background: $secondary;
    border: 0;
    margin-bottom: 10px;
    position: relative;

    .more-dropdown {
      right: 70px !important;
      position: absolute !important;
      top: 11px;
      z-index: 5 !important;
    }
    .delete-btn {
      right: 120px !important;
      position: absolute !important;
      top: 11px;
      z-index: 5 !important;
    }
    .accordion-header {
      .accordion-button {
        background: $secondary;
        box-shadow: none;
        padding: 20px;
        display: inline-block;
        &::first-letter {
          text-transform: capitalize;
        }
        &::after {
          content: '';
          display: block;
          width: 37px;
          height: 37px;
          background: url('../img/ArrowDownYellow.svg') no-repeat center $input-Primary;
          border-radius: 5px;
          position: absolute;
          right: 20px;
          top: 11px;
        }

        h6 {
          margin-bottom: 0;
        }

        .btn-sm {
          width: 37px;
          height: 37px;
          line-height: 10px;
        }

        .edit-btn {
          right: 70px;
          position: absolute;
        }
      }
    }
  }

  &.accordion-secondary {
    .accordion-item {
      background: $accordionSecondary;

      .accordion-button {
        background: $accordionSecondary;
      }
    }
  }
}
 .userbulk-label label{
 display: block;
 height:auto !important;
}
// Calendar

.custom-calendar {
  position: relative;

  .react-datepicker-wrapper {
    display: block;
  }

  .date-custom-label {
    position: absolute;
    top: -25px;
    color: $text-primary;
  }

  &.disabled {
    .form-control {
      border: 0;
      background-color: $border;
      color: $readonly;
      cursor: default;
    }
  }

  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    color: $white;

    .react-datepicker__month-dropdown-container {
      margin-right: 30px;
    }

    .react-datepicker__year-read-view--down-arrow {
      width: 7px;
      height: 7px;
      top: 3px;
    }

    .react-datepicker__month-read-view--down-arrow {
      width: 7px;
      height: 7px;
      top: 4px;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown {
      color: #1b1b25;;
      border: 0;

      .react-datepicker__year-option,
      .react-datepicker__month-option {
        padding: 3px 0;
      }
    }

    .react-datepicker__navigation--years {
      display: none;
    }
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.24);

    .react-datepicker__triangle {
      margin-left: -50%;

      &::after {
        border-bottom-color: $input-Primary !important;
      }

      &::before {
        border-bottom-color: transparent !important;
      }
    }

    .react-datepicker__header {
      border-bottom: 0;
      background-color: $input-Primary;

      .react-datepicker__current-month,
      .react-datepicker__day-name,
      .react-datepicker-time__header {
        color: $white;
      }
    }

    .react-datepicker__navigation-icon {
      top: 5px;
    }

    .react-datepicker__day--selected {
      background-color: $input-Primary;
      border-radius: 50%;
    }

    .react-datepicker__day:hover {
      border-radius: 50%;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
    }

    .react-datepicker__input-time-container {
      margin: 0;
      padding: 10px 15px;
      background-color: #f4f4f4;

      .react-datepicker-time__input {
        input {
          border: #ccc solid 1px;
          border-radius: 4px;
          padding: 8px;
          color: $primary;
          font-size: 14px;
          min-width: 125px;

          &:focus-visible {
            outline-color: $secondary;
          }
        }
      }
    }

    .react-datepicker__time-list-item--selected {
      background-color: $input-Primary !important;
    }
  }

  .react-datepicker__input-container {
    // &::before {
    //     content: '';

    //     width: 24px;
    //     height: 26px;
    //     position: absolute;
    //     display: block;
    //     top: 11px;
    //     right: 9px;
    // }

    input {
      border: 0;
      border-radius: 5px;
      width: 100%;
      cursor: pointer;
      color: $inputPH;
      padding: 13px;
      background: url('../img/Calendar.svg') no-repeat $input-Primary;
      background-position: right 10px top 50%;
      &::placeholder {
        color: $inputPH;
        opacity: 0.5;
      }

      &.react-datepicker-ignore-onclickoutside {
        //background: url('../assets/UpBlue.svg') no-repeat;
        background-position: right 10px top 50%;
      }

      &:focus-visible {
        border-color: $border;
        outline: 0;
      }
    }

    button {
      text-align: left;
      padding-left: 50px;
      padding-right: 40px;
    }
  }

  &.form-sm {
    .react-datepicker__input-container {
      &::before {
        top: 7px;
        right: 7px;
      }

      input {
        padding: 8px;
      }
    }
  }
}

//Stepper

.StepperContainer-0-2-1 {
  .StepContainer-0-2-2 {
    .Connector-0-2-15 {
      border-color: $text-seconday;
    }

    span {
      &.active {
        border-color: $text-primary;
      }
    }

    .StepButton-0-2-4 {
      background: transparent;
      border: $text-seconday solid 1px;

      &.completed,
      &.active {
        background: $btn-primary;
        border-color: $btn-primary;
      }
    }
  }
}

.z-index {
  z-index: 11111;
}

.details-view {
  .col-xl {
    min-width: 20%;
    max-width: 20%;
    margin-top: 20px;
  }

  label {
    font-size: 13px;
    color: $text-seconday;
    margin-bottom: 5px;
  }

  p {
    color: $text-primary;
  }
}

.react-audio-player {
  width: 100%;
}

.questionanswer {
  padding: 15px;
  border: $border solid 1px;
  border-radius: 10px;
  margin-bottom: 15px;

  ol {
    margin-bottom: 0;
    padding-left: 20px;

    li {
      padding-left: 10px;
    }
  }
}

.loader-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1111;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-img {
  background: url('../img/Gradient-bg.jpg') no-repeat #06265b;
  background-size: 100%;
  background-attachment: fixed;
}

.br-10 {
  border-radius: 10px;
}

// Modal
.modal {
  .modal-content {
    background-color: $secondary;

    .modal-header {
      border-bottom-color: $border;

      .btn-close {
        background: url('../img/Close.svg') no-repeat;
      }

      .modal-title {
        font-size: 18px;
        color: $white;
      }
    }

    .modal-body {
      padding-top: 25px;

      h4 {
        border-bottom: $border solid 1px;
        padding-bottom: 12px;
        margin-bottom: 20px;
      }
    }

    .modal-footer {
      border-top-color: $border;

      .btn {
        margin-left: 20px;
      }
    }
  }
  .modal-sm {
    min-width: 400px;
  }
}

.reviewproof-main {
  padding: 10px 50px;

  p {
    margin-top: 5px;
  }

  .slick-next {
    background: url('../img/Icn_Next.svg') no-repeat;
    background-size: cover !important;
    right: -50px;

    &:hover,
    &:focus {
      background: url('../img/Icn_Next.svg') no-repeat;
      opacity: 1;
    }

    &.slick-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .slick-prev {
    background: url('../img/Icn_Prev.svg') no-repeat;
    background-size: cover !important;
    left: -50px;

    &:hover,
    &:focus {
      background: url('../img/Icn_Prev.svg') no-repeat;
      opacity: 1;
    }

    &.slick-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .slick-prev,
  .slick-next {
    opacity: 0.9;
    width: 40px;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
    margin-top: -22px;

    &::before {
      display: none;
    }

    &:hover {
      &:enabled {
        opacity: 1;
      }
    }

    &[disabled] {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}

//Image uploader
.image-uploader {
  border: 2px $btn-primary dashed;
  // background: $btn-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-height: calc(100% - 24px);
  //max-height: calc(100% - 24px);
  padding: 0px;
  cursor: pointer;
  > div {
    min-width: 150px;
    min-height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 200px;
    max-height: 250px;
  }

  .drag-icon {
    svg {
      fill: blue;
      max-width: 50px;

      path {
        fill: $btn-primary;
      }
    }
  }

  .or {
    color: #e0e0e0;
    // @include font-14;
    font-weight: $semi-bold;
    padding: 15px 0;
  }

  .upload {
    display: flex;
    align-items: center;
    // @include font-18;
    color: $text-primary;

    svg {
      fill: $btn-primary;
      margin-right: 14px;
    }
  }
  .hidebtn {
    display: none;
  }
}

.tags-selector {
  [class$='-control'] {
    background-color: $input-Primary;
    border: 1px solid $input-Primary;
    overflow: hidden;
    padding: 5px 11px 5px;
    border-radius: 5px;

    [class$='-ValueContainer'] {
      padding-left: 0;

      [class$='-multiValue'] {
        background-color: $primary;
        border-radius: 2px;
        color: $white;
        font-size: 13px;

        [class$='-MultiValueGeneric'] {
          color: $text-primary;
        }

        svg {
          path {
            fill: $yellow;
          }
        }
      }
      [class$='-singleValue'] {
        color: $inputPH !important;
      }
      input {
        color: $inputPH !important;
        font-size: 1rem !important;
      }
    }
    [class$='-indicatorSeparator'] {
      display: none;
    }
    input {
      color: $inputPH !important;
    }
  }

  [class$='-menu'] {
    background-color: $input-Primary;

    [class$='-option'] {
      background-color: transparent;

      &:hover {
        background-color: $primary;
      }
    }
  }
  [class$='-singleValue'] {
    color: $inputPH !important;
  }
}

.general-tag {
  background-color: $green;
  padding: 3px 10px;
  border-radius: 15px;
  color: $white;
  margin-left: 3px;
}

td {
  .general-tag {
    font-size: 10px;
    padding: 3px 5px;
  }
}

.react-datepicker-popper {
  z-index: 2;
}

.pagenotfound {
  font-size: 100px;
  font-weight: bold;
  color: $text-seconday;
  display: flex;
  align-items: center;
  svg {
    margin-right: 30px;
  }
}
.upload-info {
  display: flex;
  justify-content: center;
  ul {
    border: $border solid 1px;
    border-radius: 5px;
    padding: 10px 15px 10px 30px;
    max-width: 450px;
    li {
      font-size: 12px;
      color: $text-seconday;
    }
  }
}
.notes-main {
  border: $border solid 1px;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  background-color: $primary;
  p {
    margin-bottom: 0;
  }
}
.pt-7 {
  padding-top: 7px;
}
.task-event-img {
  svg {
    width: 20px;
  }
}
.no-pointer {
  pointer-events: none;
}
.disabled-link {
  color: #ccc;
  cursor: not-allowed;
  text-decoration: none;
  background-color: #6c757d;
}
// img.bm-cross {
//   display: none;
// }
.scroll-color {
  > div:nth-child(3) {
    div {
      background-color: $text-seconday !important;
    }
  }
}
.fix-width{
  width: 250px;
  word-break: break-all;
  max-width: 250px;
}
.fileupload-survey{
  background-color: #682FF6;
    height: auto !important;
    margin: 0 auto;
    border-radius: 4px;   
    padding: 10px;
    margin: 0 10px;
    width: auto !important;
    span{
      margin-top: 0 !important;
    }
}
.record-main{
.video-recorder{
  width: 100%;
}
.record-button-nav{
  text-align: center;
  .btn-outline-light{
    border: 0;
    padding: 5px;
    border-radius: 25px;
    margin: 10px;
    &:hover{
      background-color: $input-Primary;
    }
  }
}
.hidden
{
  display: none;
}
}

.form-check-report{
  margin-bottom: 1.175rem !important;
  input{
    margin-right: 10px !important;
  }
  label{
    padding-top: 3px !important;
  }
}

.m-grp-pagination{
  --bs-pagination-hover-bg:#252f52;
  --bs-pagination-focus-bg:#252f52;
  --bs-pagination-disabled-bg:#313D67 !important; 
}


.m-grp-pagination .disabled .page-link {
  color: #4f5585;
  pointer-events: none;
  background-color: #2B3157;
}
// Pagination
.m-grp-pagination {
  margin-bottom: 0;

  li {
    box-shadow: none;

    a {
      // font-size: $main-fs;
      // font-weight: $medium;
      color: #528dee !important;
      padding: 6px 15px;
      border: $border solid 1px;
      display: inline-flex;
      background: #252f52;

      &:hover {
        color: $text-primary;
      }
    }

  }
}
.howitworks{
  max-width: 700px;
  padding-top: 130px;
}
.generalheader{
  padding: 15px 30px;
  background: rgb(138,5,176);
              background: linear-gradient(90deg, rgba(138,5,176,1) 0%, rgba(11,102,155,1) 100%);
              position: fixed;
              width: 100%;
              top: 0;
              z-index: 12;
              img{
                max-width: 75px;
              }
}
#RFS-Connector{
  border-color: #9CAFCE !important;
}